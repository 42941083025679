import React, { Component } from 'react';
import styled from 'styled-components'
import Layout from '../components/layout';
import { colors } from '../colors/colors';

//NPM
import Loader from 'react-loader-spinner';
import { LoginUser, Register, sendPasswordReset } from '../services/parse-bridge';
import { navigate, Link } from 'gatsby';
import '../components/toast.css'
import { AiOutlineUpload } from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SEO from '../components/seo';

class LoginPage extends Component {
    state = {
        fullName: "",
        email: "",
        password: "",
        gender: "Male",
        loading: false,
        login: false,
        fileSelected: false,
        profilePicture: ""
    }

    notify = (message = "Invalid username/password") => {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_CENTER,
            className: 'toast-class',
            bodyClassName: "toast-body",
            progressClassName: 'toast-progress'
        });
    };

    updateInput = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    createFlyer = e => {
        if (e.target.files[0]) {
            //Add the file to state
            this.setState({ fileSelected: true, profilePicture: e.target.files[0] })
        } else {
            console.log('No file selected')
            this.setState({ fileSelected: false })
        }
    }

    validateForm = () => {
        const { fullName, email, password, login } = this.state
        var valid

        if ((fullName.trim() === "" && login === false) || email.trim() === "" || password === "") {
            alert("Please fill all fields")
            valid = false

        } else if (password.length < 6) {
            alert("Please enter a password with at least 6 characters")
            valid = false
        } else {
            valid = true
        }

        return valid
    }

    handleSubmit = (event) => {
        this.setState({ loading: true })
        event.preventDefault()
        const { fullName, email, password, login, profilePicture, gender } = this.state

        if (this.validateForm()) {

            //Check if logging in or signing up
            if (login) {
                LoginUser(email, password).then(res => {
                    if (res) {
                        this.setState({ loading: false })
                        navigate('/')

                    } else {
                        this.setState({ loading: false })
                        this.notify()
                    }
                })

            } else {
                Register(fullName, email, profilePicture, gender, password).then(res => {
                    this.setState({ loading: false })
                    if (res.message) {
                        this.notify(`Uh-oh: ${res.message}`)
                        console.log("Something went wrong signing them up")
                    } else {
                        navigate('/')
                    }
                })
            }

        } else {
            this.setState({ loading: false })
            return
        }
    }

    triggerPasswordReset = () => {
        let email = prompt("Enter the email address you used to sign up and we will send a password reset");

        if (email !== "") {
            //Send reset and alert
            console.log(email)
            sendPasswordReset(email).then((res) => {
                if (res) {
                    alert("We've sent your password reset.")
                }
            })
        } else {

        }
    }


    render() {
        const { fullName, email, password, gender, loading, login, fileSelected } = this.state


        if (login) {
            return (
                <Layout noNav noFooter>
                    <SEO
                        title="Home"
                        keywords={[
                            `yup`,
                            `life`,
                            `FOC`,
                            `Festival`,
                            `Colour`,
                            `Backyard`,
                            `Melee`,
                            `Events`,
                            `Fete`,
                            `Trinidad`,
                            `Carnival`,
                            `Party`,
                            `Fete`
                        ]}
                    />
                    <ToastContainer />
                    <Container>
                        <Left>
                            <Logo src={require('../images/logo.png')} />
                            <Title>Login now to access everything Yup Life</Title>
                            <Form onSubmit={this.handleSubmit}>
                                <Input type="email" value={email} name="email" onChange={this.updateInput} placeholder="Email Address" />
                                <Input type="password" value={password} name="password" onChange={this.updateInput} placeholder="Password" />
                                <Button>Login</Button>
                                {loading &&
                                    <>
                                        <CenterBlock>
                                            <Loader
                                                type="ThreeDots"
                                                color="#B4271D"
                                                height="50"
                                                width="50"
                                            />
                                        </CenterBlock>
                                    </>}
                                <Instructions type="button" onClick={() => this.triggerPasswordReset()}>I forgot my password</Instructions>
                                <Instructions type="button" onClick={() => this.setState({ login: false })}>Need an account? Sign up in seconds</Instructions>
                            </Form>
                        </Left>
                        <Right><Cover src={require('../images/JumboFOC-min.jpg')} /></Right>
                    </Container>
                </Layout>
            )
        }

        return (<Layout noNav noFooter>
            <SEO
                title="Home"
                keywords={[
                    `yup`,
                    `life`,
                    `FOC`,
                    `Festival`,
                    `Colour`,
                    `Backyard`,
                    `Melee`,
                    `Events`,
                    `Fete`,
                    `Trinidad`,
                    `Carnival`,
                    `Party`,
                    `Fete`
                ]}
            />
            <ToastContainer />
            <Container>
                <Left>
                    <Logo src={require('../images/logo.png')} />
                    <Title>Sign up now to access everything Yup Life</Title>
                    <Form onSubmit={this.handleSubmit}>
                        <Input type="text" value={fullName} name="fullName" onChange={this.updateInput} placeholder="Full Name" />
                        <Input type="email" value={email} name="email" onChange={this.updateInput} placeholder="Email Address" />
                        <Input type="password" value={password} name="password" onChange={this.updateInput} placeholder="Password (Minimum 6 characters)" />
                        <FieldFile type="file"
                            name="bodyImage"
                            id="bodyImage"
                            onChange={this.createFlyer} />
                        <FileLabel selected={fileSelected} htmlFor="bodyImage"><AiOutlineUpload /> {fileSelected ? 'Image selected' : 'Upload a Profile Picture'}</FileLabel>
                        <GenderContainer>
                            <GenderButton type="button" onClick={() => this.setState({ gender: "Male" })} selected={gender === "Male"}>Male</GenderButton>
                            <GenderButton type="button" onClick={() => this.setState({ gender: "Female" })} selected={gender === "Female"}>Female</GenderButton>
                        </GenderContainer>
                        <Button>Sign Up</Button>
                        {loading &&
                            <>
                                <CenterBlock>
                                    <Loader
                                        type="ThreeDots"
                                        color="#B4271D"
                                        height="50"
                                        width="50"
                                    />
                                </CenterBlock>
                            </>
                        }
                        <Instructions onClick={() => this.setState({ login: true })}>Already have an account? Log in</Instructions>
                        <p style={{ fontFamily: 'Lato', textAlign: 'center', fontSize: 15 }}>By registering you are agreeing to our <Link style={{ color: 'inherit', textDecoration: 'underline', fontStyle: 'italic' }} to="/terms-of-service">terms of use</Link></p>
                    </Form>
                </Left>
                <Right><Cover src={require('../images/JumboFOC-min.jpg')} /></Right>
            </Container>
        </Layout>);
    }
}

const CenterBlock = styled.div`
	text-align: center;
	display: block;
	margin: 0 auto;
`;


const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: -77px;

  @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

`;
const Left = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
`;
const Right = styled.div`
  @media (max-width: 768px) {
      display: none;
  }
`;
const Cover = styled.img`
  height: 100vh;
  width: 100%;
  object-fit: cover;

`;
const Logo = styled.img`
  object-fit: contain;
  width: 60px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
      margin: 20px auto;
  }
  
`;
const Title = styled.h1`
  font-family: "bebasbold";
  color: ${colors.red};
  font-size: 55px;

  @media (max-width: 768px) {
  font-size: 45px;
  text-align: center;
  }

  @media (max-width: 400px) {
  font-size: 35px;
  text-align: center;
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;

`;
const Input = styled.input`
  background: none;
  font-size: 16px;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #595959;
  color: white;
  font-family: "Lato";
  transition: 0.2s ease;

  :focus { 
    outline: none;
    border: 1px solid ${colors.red};

  }
`;
const FieldFile = styled.input`
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`;

const FileLabel = styled.label`
  margin: 5px 0;
  font-size: 1em;
    font-weight: 500;
    color: white;
  font-family: "Lato", sans-serif;
    background-color: none;
  border: 1px solid #595959;
    display: inline-block;
    padding: 10px;
    transition: 0.3s ease;
    cursor: pointer;

    :hover { 
        color: white;
    background-color: black;
    }
`;
const GenderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px 0;

`;
const GenderButton = styled.button`
  background: ${props => props.selected ? 'red' : 'none'};
  color: ${props => props.selected ? 'white' : 'white'};
  border: 1px solid ${colors.red};
  padding: 10px 25px;
  font-family: "bebasbold", sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s ease;

  :focus {
      outline: none;
  }
`;
const Instructions = styled.button`
  font-family: "Lato";
  font-style: italic;
  color: #6E6E6E;
  background: none;
  border: none;
  margin: 20px 0;
  cursor: pointer;

  :focus {
      outline: none;
  }
`;
const Button = styled.button`
  background: ${colors.red};
  width: 100%;
  border: none;
  font-family: "bebasbold";
  color: white;
  padding: 10px;
  font-size: 20px;
  margin: 20px 0 0;
  cursor: pointer;
  transition: 0.3s ease;

  :hover {
      opacity: 0.9;
  }

  :focus {
      outline: none;
  }
`;
export default LoginPage;